import "./Banner.scss"

export default function Banner({ slides }) {
    return (
        <div className="banner-custom" style={{backgroundImage: `url(${slides[0].url})`}}>
            <div className="banner-title-container" >
                <h1 className="banner-title">{slides[0].title}</h1>
            </div>
        </div>
    )
}