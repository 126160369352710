import { t } from "i18next";
import React from "react";
import Banner from "../Components/Banner/Banner";
import ContactAddress from "../Components/ContactAddress/ContactAddress";
import ContactForm from "../Components/ContactForm/ContactForm";
import Form from "../Components/ContactForm/Form";
import Footer from "../Components/Footer/Footer";
import NavigationBar from "../Components/NavigationBar/Nav";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t } = useTranslation();
  const slides = [
    {
      url: "ContactImg.jpg",
      title: t("Contact.1"),
    },
  ];
  return (
    <div>
      <NavigationBar />
      <Banner slides={slides} />
      <ContactAddress />
      <ContactForm />
      <Form />
      <Footer />
    </div>
  );
}
