import React from "react";
import "./HomeProduct.scss";
import HomeCard1Img from "../../Images/HomeCard1Img.png";
import HomeCard2Img from "../../Images/HomeCard2Img.png";
import HomeCard3Img from "../../Images/HomeCard3Img.png";
import HomeCard4Img from "../../Images/HomeCard4Img.png";
import HomeCard5Img from "../../Images/HomeCard5Img.png";
import HomeCard6Img from "../../Images/HomeCard6Img.png";
import { FaSearchPlus, FaLink } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

const HomeProduct = () => {
  const { t } = useTranslation();
  return (
    <div className="home-product-container">
      <Fade bottom distance="20%">
        <div className="home-product-title">
          <h2>{t("HomeProduct.1")}</h2>
          <p className="home-product-text">{t("HomeProduct.2")}</p>
        </div>
      </Fade>
      {/* Product cards in grids */}
      <div className="home-product-card">
        {" "}
        <div className="home-product-card-img">
          <img src={HomeCard1Img} alt="Cement" />
          <div className="home-product-info">
            <h4>{t("HomeProduct.3")}</h4>
            <div className="home-product-icon">
              <div className="icon1">
                <span className="icon-color">
                  <FaSearchPlus
                    color="white"
                    size="20px"
                    onMouseOver={({ target }) =>
                      (target.style.color = "#F77B00")
                    }
                    onMouseOut={({ target }) => (target.style.color = "white")}
                  />
                </span>
              </div>
              <Link to="/Product">
                <FaLink
                  color="white"
                  size="20px"
                  onMouseOver={({ target }) => (target.style.color = "#F77B00")}
                  onMouseOut={({ target }) => (target.style.color = "white")}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="home-product-card-img">
          <img src={HomeCard2Img} alt="Concrete" />
          <div className="home-product-info">
            <h4>{t("HomeProduct.4")}</h4>
            <div className="home-product-icon">
              <FaSearchPlus
                color="white"
                size="20px"
                onMouseOver={({ target }) => (target.style.color = "#F77B00")}
                onMouseOut={({ target }) => (target.style.color = "white")}
              />
              <Link to="/Product">
                <FaLink
                  color="white"
                  size="20px"
                  onMouseOver={({ target }) => (target.style.color = "#F77B00")}
                  onMouseOut={({ target }) => (target.style.color = "white")}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="home-product-card-img">
          <img src={HomeCard3Img} alt="Hollow Bricks" />
          <div className="home-product-info">
            <h4>{t("HomeProduct.5")}</h4>
            <div className="home-product-icon">
              <FaSearchPlus
                color="white"
                size="20px"
                onMouseOver={({ target }) => (target.style.color = "#F77B00")}
                onMouseOut={({ target }) => (target.style.color = "white")}
              />
              <Link to="/Product">
                <FaLink
                  color="white"
                  size="20px"
                  onMouseOver={({ target }) => (target.style.color = "#F77B00")}
                  onMouseOut={({ target }) => (target.style.color = "white")}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="home-product-card-img">
          <img src={HomeCard4Img} alt="Solid Bricks" />
          <div className="home-product-info">
            <h4>{t("HomeProduct.6")}</h4>
            <div className="home-product-icon">
              <FaSearchPlus
                color="white"
                size="20px"
                onMouseOver={({ target }) => (target.style.color = "#F77B00")}
                onMouseOut={({ target }) => (target.style.color = "white")}
              />
              <Link to="/Product">
                <FaLink
                  color="white"
                  size="20px"
                  onMouseOver={({ target }) => (target.style.color = "#F77B00")}
                  onMouseOut={({ target }) => (target.style.color = "white")}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="home-product-card-img">
          <img src={HomeCard5Img} alt="Prefab House" />
          <div className="home-product-info">
            <h4>{t("HomeProduct.7")}</h4>
            <div className="home-product-icon">
              <FaSearchPlus
                color="white"
                size="20px"
                onMouseOver={({ target }) => (target.style.color = "#F77B00")}
                onMouseOut={({ target }) => (target.style.color = "white")}
              />
              <Link to="/Product">
                <FaLink
                  color="white"
                  size="20px"
                  onMouseOver={({ target }) => (target.style.color = "#F77B00")}
                  onMouseOut={({ target }) => (target.style.color = "white")}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="home-product-card-img">
          <img src={HomeCard6Img} alt="refab Blocks" />
          <div className="home-product-info">
            <h4>{t("HomeProduct.8")}</h4>
            <div className="home-product-icon">
              <FaSearchPlus
                color="white"
                size="20px"
                onMouseOver={({ target }) => (target.style.color = "#F77B00")}
                onMouseOut={({ target }) => (target.style.color = "white")}
              />
              <Link to="/Product">
                <FaLink
                  color="white"
                  size="20px"
                  onMouseOver={({ target }) => (target.style.color = "#F77B00")}
                  onMouseOut={({ target }) => (target.style.color = "white")}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeProduct;
