import React from "react";
import "./HomeWhyUs.scss";
import WhyusIcon from "../../Images/WhyusIcon.png";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

const HomeWhyUs = () => {
  const { t } = useTranslation();
  return (
    <div className="home-whyus-container">
      <div className="home-whyus-wrap">
        <Fade bottom distance="20%">
          <div className="home-whyus-title">
            <h2>{t("HomeWhyUs.1")}</h2>
          </div>
        </Fade>
        <div className="home-whyus-cards">
          <Fade bottom distance="20%">
            <div className="home-whyus-content">
              <div className="home-whyus-icon">
                <img src={WhyusIcon} />
              </div>
              <div className="home-whyus-text">
                <h3>{t("HomeWhyUs.2")}</h3>
                <p>{t("HomeWhyUs.3")}</p>
              </div>
            </div>
          </Fade>
          <Fade bottom distance="40%">
            <div className="home-whyus-content">
              <div className="home-whyus-icon">
                <img src={WhyusIcon} />
              </div>
              <div className="home-whyus-text">
                <h3>{t("HomeWhyUs.4")}</h3>
                <p>{t("HomeWhyUs.5")}</p>
              </div>
            </div>
          </Fade>
          <Fade bottom distance="60%">
            <div className="home-whyus-content">
              <div className="home-whyus-icon">
                <img src={WhyusIcon} />
              </div>
              <div className="home-whyus-text">
                <h3>{t("HomeWhyUs.6")}</h3>
                <p>{t("HomeWhyUs.7")}</p>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default HomeWhyUs;
