import React from "react";
import "./ContactForm.scss";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

const ContactForm = () => {
  const { t } = useTranslation();
  return (
    <div className="form-container">
      <Fade bottom distance="20%">
        <div className="form-title">
          <h2>{t("ContactForm.1")}</h2>
          <p>{t("ContactForm.2")}</p>
        </div>
      </Fade>
    </div>
  );
};

export default ContactForm;
