import React from "react";
import Nav from "../Components/NavigationBar/Nav";
import HomeBanner from "../Components/HomeBanner/HomeBanner";
import HomeAbout from "../Components/HomeAbout/HomeAbout";
import HomeProduct from "../Components/HomeProduct/HomeProduct";
import HomeNumbers from "../Components/HomeNumbers/HomeNumbers";
import HomeWhyUs from "../Components/HomeWhyUs/HomeWhyUs";
import Footer from "../Components/Footer/Footer";

const Home = () => {
  return (
    <div>
      <Nav />
      <HomeBanner />
      <HomeAbout />
      <HomeProduct />
      <HomeNumbers />
      <HomeWhyUs />
      <Footer />
    </div>
  );
};

export default Home;
