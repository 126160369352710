import React from "react";
import NavigationBar from "../Components/NavigationBar/Nav";
import Banner from "../Components/Banner/Banner";
import ProductIntro from "../Components/ProductIntro/ProductIntro";
import ProductFeature from "../Components/ProductFeature/ProductFeature";
import Footer from "../Components/Footer/Footer";
import { useTranslation } from "react-i18next";

function Product() {
  const {t} = useTranslation()
  const slides = [
    {
      url: "banner-product.png",
      title: t("Product.1"),
    },
  ];
  return (
    <div>
      <NavigationBar />
      <Banner slides={slides} />
      <ProductIntro />
      <ProductFeature />
      <Footer />
    </div>
  );
}

export default Product;
