import "./AboutMission.scss";
import visionIcon from "../../Images/vision-icon.png";
import missionIcon from "../../Images/mission-icon.png";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

export default function AboutMission() {
  const { t } = useTranslation();
  return (
    <div className="mission-vision">
      <Fade bottom distance="20%">
        <h3 className="mission-vision-header">{t("AboutMission.1")}</h3>
      </Fade>
      <div className="mission">
        <div className="mission-left"></div>
        <div className="mission-right">
          <Fade bottom distance="20%">
            <h2 className="mission-header">
              <img
                className="mission-icon"
                src={missionIcon}
                alt="mission icon"
              />

              {t("AboutMission.2")}
            </h2>
          </Fade>
          <Fade bottom distance="40%">
            <p className="mission-text">{t("AboutMission.3")}</p>
          </Fade>
        </div>
      </div>
      <div className="vision">
        <div className="vision-left">
          <Fade bottom distance="40%">
            <h2 className="vision-header">
              <img className="vision-icon" src={visionIcon} alt="vision icon" />
              {t("AboutMission.4")}
            </h2>
          </Fade>
          <Fade bottom distance="40%">
            {" "}
            <p className="vision-text">{t("AboutMission.5")}</p>
          </Fade>
        </div>
        <div className="vision-right"></div>
      </div>
    </div>
  );
}
