import ProductCardFirst from "./ProductCardFirst/ProductCardFirst";
import ProductCardLeft from "./ProductCardLeft/ProductCardLeft";
import ProductSlider from "../ProductSlider/ProductSlider";
import ProductBottomCard from "./ProductBottomCard";
import ProductCardTwo from "./ProductCardTwo";
import { useTranslation } from "react-i18next";

export default function ProductFeature() {
  const { t } = useTranslation();
  const cards = [
    {
      url: "product-img1.png",
      title: t("ProductFeature.1"),
      body: t("ProductFeature.2"),
    },
    {
      url: "product-img3.png",
      title: t("ProductFeature.3"),
      body: t("ProductFeature.4"),
    },
  ];

  return (
    <div>
      <ProductCardFirst cards={cards} />
      <ProductCardTwo />
      <ProductCardLeft cards={cards} />
      <ProductSlider />
      <ProductBottomCard />
    </div>
  );
}
