import "./ProductCardRight.scss";
import Fade from "react-reveal/Fade";

export default function ProductCardRight({ cards }) {
  return (
    <div className="productCardRight">
      <div className="productCardRight-left">
        <img
          className="productCardRight-img"
          src={cards[0].url}
          alt="product one"
        />
      </div>
      <Fade right distance="20%">
        <div className="productCardRight-right">
          <h3 className="productCardRight-title">{cards[0].title}</h3>
          <p className="productCardRight-text"> {cards[0].body}</p>
        </div>
      </Fade>
    </div>
  );
}
