import React from "react";
import "./Footer.scss";
import FooterLogo from "../../Images/FooterLogo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="footer-wrapper">
      <div className="footer-container">
        <div className="footer-logo">
          <Link to="/">
            <img src={FooterLogo} />
          </Link>
        </div>
        <div className="nav-items">
          <ul className="nav-item-list">
            <Link to="/">
              <li>
                <p>{t("Footer.1")}</p>
              </li>
            </Link>
            <Link to="/About">
              <li>
                <p>{t("Footer.2")}</p>
              </li>
            </Link>
            <Link to="/Product">
              <li>
                <p>{t("Footer.3")}</p>
              </li>
            </Link>
            <Link to="/Contact">
              <li>
                <p>{t("Footer.4")}</p>
              </li>
            </Link>
          </ul>
        </div>
        <div className="footer-contact">
          <p>{t("Footer.5")}</p>
          <p>{t("Footer.6")}</p>
          <p>{t("Footer.7")}</p>
        </div>
      </div>
      {/* <div>
        <hr className="footer-hr-line" />
      </div> */}
      <div className="footer-company-name">
        <p>{t("Footer.8")}</p>
      </div>
    </div>
  );
};

export default Footer;
