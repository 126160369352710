import React, { useState } from "react";
import "./Nav.scss";
import {
  Offcanvas,
  NavDropdown,
  Navbar,
  Nav,
  Container,
  Button,
} from "react-bootstrap";
import { RxGlobe } from "react-icons/rx";
import LogoImg from "../../Images/LogoImg.png";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const lngs = {
  en: { nativeName: "English" },
  chi: { nativeName: "中文" },
  fr: { nativeName: "Français" },
};

function NavigationBar() {
  const { t, i18n } = useTranslation();

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  function languageClick(languages) {
    i18n.changeLanguage(languages);
  }
  const languages = ["en", "fr", "chi"];
  return (
    <>
      {["lg"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className="mb-3 px-5" // Controling the margin and padding of the elements.
          fixedTop
          variant="dark"
          style={{ backgroundSize: "0", backgroundColor: "#09264E" }} // changing the background of the navbar.
        >
          <Container fluid>
            <Navbar.Brand href="/">
              <img src={LogoImg} width="100px" height="50px" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              {/* <Offcanvas.Header closeButton>
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-${expand}`}
                ></Offcanvas.Title>
              </Offcanvas.Header> */}
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <NavLink to="/">
                    <Nav.Link href="/">{t("Nav.1")}</Nav.Link>
                  </NavLink>
                  <NavLink to="/About">
                    <Nav.Link href="/About">{t("Nav.2")}</Nav.Link>
                  </NavLink>
                  <NavLink to="/Product">
                    <Nav.Link href="/Product">{t("Nav.3")}</Nav.Link>
                  </NavLink>
                  <NavLink to="/Contact">
                    <Nav.Link href="/Contact">
                      <Button
                        variant="outline-success"
                        style={{
                          background:
                            "linear-gradient(90deg, rgba(0,81,111,1) 0%, rgba(9,166,226,1) 0%, rgba(0,81,111,1) 100%)",
                        }}
                      >
                        {t("Nav.4")}
                      </Button>
                    </Nav.Link>
                  </NavLink>
                  <NavDropdown
                    title={<RxGlobe size="24px" />} // Adding the dropdown icon, the globe icon.
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item
                      href=""
                      onClick={() => languageClick("en")}
                    >
                      English
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      href=""
                      onClick={() => languageClick("chi")}
                    >
                      中文
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      href=""
                      onClick={() => languageClick("fr")}
                    >
                      Français
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default NavigationBar;
