import React from "react";
import { useEffect } from "react";
import "./HomeAbout.scss";
import AboutHomeImg1 from "../../Images/AboutHomeImg1.png";
import AboutHomeImg2 from "../../Images/AboutHomeImg2.png";
import AboutHomeImg3 from "../../Images/AboutHomeImg3.png";
import { useTranslation } from "react-i18next";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Fade from 'react-reveal/Fade';
const HomeAbout = () => {
  const { ref, inView } = useInView({
    threshold:0.2
  });
  const animation = useAnimation();
  const animo = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        x: 0,
        transition: {
          type: "Tween",
          duration: 1.5,
          bounce: 0.2,
        },
      });
    }
    if (!inView) {
      animation.start({
        x: "-100vw",
      });
    }
    console.log("use effect hook, inView =", inView);
  }, [inView]);

  useEffect(() => {
    if (inView) {
      animo.start({
        x: 0,
        transition: {
          type: "Tween",
          duration: 1.5,
          delay:0.5,
          bounce: 0.2,
        },
      });
    }
    if (!inView) {
      animo.start({
        x: "-100vw",
      });
    }
    console.log("use effect hook, inView =", inView);
  }, [inView]);

  const { t } = useTranslation();
  return (
    <div className="home-about-container">
      <div className="home-about-highlight">
        <div ref={ref} className="about-highlight">
          <motion.div className="about-highlight1" animate={animation}>
            <h4>01</h4>
            <h3>{t("HomeAbout.1")}</h3>
            <p>{t("HomeAbout.2")}</p>
          </motion.div>
          <motion.div className="about-highlight2" animate={animo}>
            <h4>02</h4>
            <h3>{t("HomeAbout.3")}</h3>
            <p>{t("HomeAbout.4")}</p>
          </motion.div>
        </div>

        <div className="home-about-detail">
          <Fade bottom distance='20%'>
            <div className="home-about-content">
              <h2>{t("HomeAbout.5")}</h2>
              <p className="home-about-text1" id="#about-home-p">
                {t("HomeAbout.6")}
              </p>
              <p className="home-about-text2">{t("HomeAbout.7")}</p>
            </div>
          </Fade>
          <div className="about-home-images">
            <Fade bottom distance="20%">
              <div>
                <img src={AboutHomeImg2} alt="Cement Block" />
              </div>
            </Fade>
            <Fade bottom distance="20%">
              <div>
                <img src={AboutHomeImg1} alt="Concrete" />
              </div>
            </Fade>
            <Fade bottom distance="20%">
              <div>
                <img src={AboutHomeImg3} alt="a Prefab Homes" />
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAbout;
