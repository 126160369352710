import i18n from 'i18next';
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const languages = ['en', 'fr', 'chi'];
i18n
  .use(Backend)
  // detect user language

  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    supportedLngs: ["en", "fr", "chi"],
    fallbackLng: "en",
    // resources: {
    //   en:{
    //     translation: {
    //       Footer: 'Home'
    //     }
    //   }
    // },
    debug: true,
    whitelist: languages,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
