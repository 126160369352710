import "./_AboutDetail.scss";
import aboutImage from "../../Images/about-detail.png";
import { BsFillCheckSquareFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

export default function AboutDetail() {
  const { t } = useTranslation();
  return (
    <div className="about-detail">
      <img
        className="about-detail-image"
        src={aboutImage}
        alt="About us images"
      />
      <div className="about-detail-content">
        <Fade bottom distance="30%">
          <h4 className="about-detail-top-title">{t("AboutDetail.1")}</h4>
        </Fade>
        <Fade bottom distance="40%">
          <h3 className="about-detail-title">{t("AboutDetail.2")}</h3>
        </Fade>
        <Fade bottom distance="60%">
          <p className="about-detail-text">{t("AboutDetail.3")}</p>
        </Fade>
        <div className="about-detail-list">
          <ul className="about-detail-list-1">
            <Fade bottom diatance="20%">
              <div className="about-detail-list-item">
                <span className="about-detail-list-icon">
                  <BsFillCheckSquareFill />
                </span>
                <li>{t("AboutDetail.4")}</li>
              </div>
            </Fade>
            <Fade bottom diatance="40%">
              <div className="about-detail-list-item">
                <span className="about-detail-list-icon">
                  <BsFillCheckSquareFill />
                </span>
                <li>{t("AboutDetail.5")}</li>
              </div>{" "}
            </Fade>
            <Fade bottom diatance="60%">
              <div className="about-detail-list-item">
                <span className="about-detail-list-icon">
                  <BsFillCheckSquareFill />
                </span>
                <li>{t("AboutDetail.6")}</li>
              </div>{" "}
            </Fade>
          </ul>

          <ul className="about-detail-list-2">
            <Fade bottom diatance="20%">
              <div className="about-detail-list-item">
                <span className="about-detail-list-icon">
                  <BsFillCheckSquareFill />
                </span>
                <li>{t("AboutDetail.7")}</li>
              </div>
            </Fade>
            <Fade bottom diatance="40%">
              <div className="about-detail-list-item">
                <span className="about-detail-list-icon">
                  <BsFillCheckSquareFill />
                </span>
                <li>{t("AboutDetail.8")}</li>
              </div>
            </Fade>
            <Fade bottom diatance="60%">
              <div className="about-detail-list-item">
                <span className="about-detail-list-icon">
                  <BsFillCheckSquareFill />
                </span>
                <li>{t("AboutDetail.9")}</li>
              </div>
            </Fade>
          </ul>
        </div>
      </div>
    </div>
  );
}
