import "./ProductSlider.scss";
import HorizontalScroll from "react-scroll-horizontal";
import img1 from "../../Images/img1.png";
import img2 from "../../Images/img2.png";
import img3 from "../../Images/Image-prefab.jpg"
import { useTranslation } from "react-i18next";

export default function ProductSlider() {
  const { t } = useTranslation();
  return (
    // <div className="box-container">
    <div className="product-slider-container"
    >
        <div className="product-slider-card">
          <img className="product-slider-img" src={img1} alt="product one" />
          <div className="product-slider-content">
            <h3 className="product-slider-content-title">
              {t("ProductSlider.1")}
            </h3>
            <p className="product-slider-content-text">{t("ProductSlider.2")}</p>
          </div>
        </div>
        <div className="product-slider-card">
          <img className="product-slider-img" src={img2} alt="product two" />
          <div className="product-slider-content">
            <h3 className="product-slider-content-title">
              {t("ProductSlider.3")}
            </h3>
            <p className="product-slider-content-text">{t("ProductSlider.4")}</p>
          </div>
        </div>

        <div className="product-slider-card">
          <img className="product-slider-img" src={img3} alt="product two" />
          <div className="product-slider-content">
            <h3 className="product-slider-content-title">
              {t("ProductSlider.5")}
            </h3>
            <p className="product-slider-content-text">{t("ProductSlider.6")}</p>
          </div>
        </div>
        {/* <div className="product-slider-card product-slider-card-2">
          <img className="product-slider-img" src={img2} alt="product two" />
          <div className="product-slider-content">
            <h3 className="product-slider-content-title">
              {t("ProductSlider.7")}
            </h3>
            <p className="product-slider-content-text">{t("ProductSlider.8")}</p>
          </div>
        </div> */}
    </div>
    // </div>
  );
}
