import ProductCardRight from "./ProductCardRight/ProductCardRight";
import { useTranslation } from "react-i18next";

export default function ProductBottomCard() {
  const { t } = useTranslation();
  const cards = [
    {
      url: "product-img4.png",
      title: t("ProductBottomCard.1"),
      body:  t("ProductBottomCard.2"),
    },
  ];

  return (
    <div>
      <ProductCardRight cards={cards} />
    </div>
  );
}
