import "./ProductCardLeft.scss";
import Fade from "react-reveal/Fade";

export default function ProductCardLeft({ cards }) {
  return (
    <div className="productCardLeft">
      <Fade left distance="20%">
        <div className="productCardLeft-left">
          <h3 className="productCardLeft-title">{cards[1].title}</h3>
          <p className="productCardLeft-text"> {cards[1].body}</p>
        </div>
      </Fade>
      <div className="productCardLeft-right">
        <img
          className="productCardLeft-img"
          src={cards[1].url}
          alt="product one"
        />
      </div>
    </div>
  );
}
