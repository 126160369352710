import React, { useEffect, useState } from "react";
import SliderContent from "./SliderContent";
import Dots from "./Dots";
import Pagination from "./Pagination";
// import sliderImage from "./sliderImage";
import "./HomeBanner.scss";
import { useTranslation, Trans } from "react-i18next";

import HomeBanner1 from "../../Images/HomeBanner1.png";
import HomeBanner2 from "../../Images/HomeBanner2.png";
import HomeBanner3 from "../../Images/HomeBanner3.png";

function BannerHome(props) {
  const { t, i18n } = useTranslation();
  const sliderImage = [
    {
      title1: t("BannerHome.1"),
      title2: t("BannerHome.2"),
      description: t("BannerHome.3"),
      urls: HomeBanner1,
    },
    {
      title1: t("BannerHome.4"),
      title2: t("BannerHome.5"),
      description: t("BannerHome.6"),
      urls: HomeBanner2,
    },
    {
      title1: t("BannerHome.7"),
      title2: t("BannerHome.8"),
      description: t("BannerHome.9"),
      urls: HomeBanner3,
    },
  ];

  const len = sliderImage.length - 1;

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 3000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <div className="slider-container">
      <SliderContent activeIndex={activeIndex} sliderImage={sliderImage} />
      <Pagination
        prevSlide={() =>
          setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
        }
        nextSlide={() =>
          setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
        }
      />
      <Dots
        activeIndex={activeIndex}
        sliderImage={sliderImage}
        onclick={(activeIndex) => setActiveIndex(activeIndex)}
      />
    </div>
  );
}

export default BannerHome;
