import React from "react";
import Banner from "../Components/Banner/Banner";
import AboutDetail from "../Components/AboutDetail/AboutDetail";
import NavigationBar from "../Components/NavigationBar/Nav";
import AboutMission from "../Components/AboutMission/AboutMission";
import AboutTeam from "../Components/AboutTeam/AboutTeam";
import Footer from "../Components/Footer/Footer";
import { useTranslation } from "react-i18next";

function About() {
  const {t} = useTranslation()
  const slides = [
    {
      url: "banner-about.jpg",
      title:t("About.1"),
    },
  ];
  return (
    <div>
      <NavigationBar />
      <Banner slides={slides} />
      <AboutDetail />
      <AboutMission />
      <AboutTeam />
      <Footer />
    </div>
  );
}

export default About;
