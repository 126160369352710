import React from "react";
import "./HomeNumbers.scss";
import NumberIcon1 from "../../Images/NumberIcon1.png";
import NumberIcon2 from "../../Images/NumberIcon2.png";
import NumberIcon3 from "../../Images/NumberIcon3.png";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import Fade from "react-reveal/Fade";

const HomeNumbers = () => {
  const { t } = useTranslation();
  return (
    <div className="numbers-containers">
      <div className="numbers-intro">
        <Fade bottom distance="20%">
          <div className="numbers-content">
            <h4>{t("HomeNumbers.1")}</h4>
            <h2 className="numbers-title">{t("HomeNumbers.2")}</h2>
            <p className="numbers-text">{t("HomeNumbers.3")}</p>
          </div>
        </Fade >
        <div className="number-box-wrapper">
          <div className="number-box">
            <Fade bottom distance="20%">
              <div className="number-box-inner">
                <div className="box-icon">
                  <img src={NumberIcon1} />
                </div>
                <div className="box-content">
                  <div className="box-title">
                    <h3>{t("HomeNumbers.4")}</h3>
                    <span>{t("HomeNumbers.5")}</span>
                  </div>
                  <div className="box-text">
                    <p className="box-text-p">{t("HomeNumbers.6")}</p>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade bottom distance="40%">
              <div className="number-box-inner">
                <div className="box-icon">
                  <img src={NumberIcon2} />
                </div>
                <div className="box-content">
                  <div className="box-title">
                    <h3>{t("HomeNumbers.7")} </h3>
                    <span>{t("HomeNumbers.8")}</span>
                  </div>
                  <div className="box-text">
                    <p className="box-text-p">{t("HomeNumbers.9")}</p>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade bottom distance="60%">
              <div className="number-box-inner">
                <div className="box-icon">
                  <img src={NumberIcon3} />
                </div>
                <div className="box-content">
                  <div className="box-title">
                    <h3 className="box-title-h">{t("HomeNumbers.10")}</h3>
                    <span>{t("HomeNumbers.11")}</span>
                  </div>
                  <div className="box-text">
                    <p className="box-text-p">{t("HomeNumbers.12")}</p>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeNumbers;
