import "./ProductIntro.scss";
import Fade from "react-reveal/Fade";

import { useTranslation } from "react-i18next";
export default function ProductIntro() {
  const { t } = useTranslation();
  return (
    <div className="productIntro">
      <div className="productIntro-left"></div>
      <div className="productIntro-right">
        <Fade bottom distance="20%">
          <Fade bottom distance="20%">
            <h3 className="productIntro-title">{t("ProductIntro.1")}</h3>{" "}
          </Fade>
          <p className="productIntro-text">{t("ProductIntro.2")}</p>
        </Fade>
      </div>
    </div>
  );
}
