import "./ProductCardFirst.scss";
import Fade from "react-reveal/Fade";

export default function ProductCardFirst({ cards }) {
  return (
    <div className="productCardFirst">
      <Fade left distance="20%">
          <div className="productCardFirst-left">
            <h3 className="productCardFirst-title">{cards[0].title}</h3>
            <p className="productCardFirst-text"> {cards[0].body}</p>
          </div>
      </Fade>
      <div className="productCardFirst-right">
        <img
          className="productCardFirst-img"
          src={cards[0].url}
          alt="product one"
        />
      </div>
      <div className="productCardFirst-bg"></div>
    </div>
  );
}
