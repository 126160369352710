import React, { Component} from "react";
import "./ContactForm.scss";
import { useTranslation, withTranslation } from "react-i18next";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        name: "",
        email: "",
        message: "",
      },
      isSubmitting: false,
      isError: false,
    };
  }
  submitForm = async (e) => {
    e.preventDefault();
    console.log(this.state);
    this.setState({ isSubmitting: true });

    const res = await fetch(
      "https://7v1ao4gnoj.execute-api.eu-west-1.amazonaws.com/prod/anjia-rwanda-contact",
      {
        method: "POST",
        body: JSON.stringify(this.state.values),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    this.setState({ isSubmitting: false });
    const data = await res.json();
    !data.hasOwnProperty("error")
      ? this.setState({ message: data.success })
      : this.setState({ message: data.error, isError: true });

    setTimeout(
      () =>
        this.setState({
          isError: false,
          message: "",
          values: { name: "", email: "", message: "" },
        }),
      1600
    );
  };

  handleInputChange = (e) =>
  this.setState({
    values: { ...this.state.values, [e.target.name]: e.target.value },
  });

render() {
  
  const { t } = this.props;

  return (
    <div>
      {" "}
      <div className="contact-form">
        <form onSubmit={this.submitForm} className="form-wrap">
          <input
            type="text"
            placeholder={t("Form.1")}
            name="name"
            class="name"
            title="title"
            value={this.state.values.name}
            onChange={this.handleInputChange}
            required
          />
          <input
            type="email"
            placeholder={t("Form.2")}
            name="email"
            id="email"
            title="email"
            value={this.state.values.email}
            onChange={this.handleInputChange}
            required
          />
          {/* <input
            type="text"
            placeholder={t("Form.3")}
            name="subject"
            id="subject"
          /> */}
          <textarea
            type="text"
            placeholder={t("Form.4")}
            name="message"
            id="message"
            title="message"
            value={this.state.values.message}
            onChange={this.handleInputChange}
            required
          />
          <div className={`message ${this.state.isError && "error"}`}>
          {this.state.isSubmitting ? "Submitting..." : this.state.message}
        </div>
          <div>
            <button type="submit" className="btn1 btn1:hover">{t("Form.5")}</button>
          </div>
          
        </form>
        
      </div>
    </div>
  );
}
}
export default withTranslation()(Form);
