import "./AboutTeam.scss";
import teamImg1 from "../../Images/team-img1.png";
import teamImg2 from "../../Images/team-img2.png";
import teamImg3 from "../../Images/team-img3.png";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

export default function AboutTeam() {
  const { t } = useTranslation();
  return (
    <div className="team">
      <div className="team-header">
        <Fade bottom distance="20%">
          <h2 className="team-header-title">{t("AboutTeam.1")}</h2>
        </Fade>
        <Fade bottopm distance="40%">
          <p className="team-header-text">{t("AboutTeam.2")}</p>
        </Fade>
      </div>

      <div className="team-gallery">
        <img
          className="team-gallery-main-img"
          src={teamImg1}
          alt=" team image"
        />
        <div className="team-gallery-col-2">
          <div className="team-gallery-text">
            <Fade bottom distance="20%">
              {" "}
              <h3 className="team-gallery-text-header">{t("AboutTeam.3")}</h3>
              <p className="team-gallery-text-body">{t("AboutTeam.4")}</p>
            </Fade>
          </div>

          <div className="team-gallery-small-imgs">
            <img src={teamImg2} alt="team image" />
            <img src={teamImg3} alt="team image" />
          </div>
        </div>
      </div>
    </div>
  );
}
