import React from "react";
import "./ContactAddress.scss";
import { FaTwitter, FaFacebookF, FaInstagram } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import { BiPhoneCall } from "react-icons/bi";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

const ContactAddress = () => {
  const { t } = useTranslation();
  return (
    <div className="contact-container">
      <div className="contact-address">
        <div className="contact-address-top">
          <Fade bottom distance="20%">
            <div className="contact-location">
              <div className="contact-img">
                <GoLocation color="white" size="30px" />
              </div>
              <h3 className="contact-title">{t("ContactAddress.1")}&nbsp;:</h3>
              <p className="contact-text">{t("ContactAddress.2")}</p>
            </div>
          </Fade>
          <Fade bottom distance="40%">
            <div className="contact-location">
              <div className="contact-img">
                <BiPhoneCall color="white" size="30px" />
              </div>
              <h3 className="contact-title">{t("ContactAddress.3")}&nbsp;:</h3>
              <p className="contact-text">{t("ContactAddress.4")}</p>
            </div>
          </Fade>
        </div>
        <div className="contact-address-bottom">
          <Fade bottom distance="60%">
            <div className="contact-location2">
              <div className="contact-img">
                <MdOutlineMarkEmailRead color="white" size="30px" />
              </div>
              <h3 className="contact-title">{t("ContactAddress.5")}&nbsp;:</h3>
              <p className="contact-text">{t("ContactAddress.6")}</p>
            </div>
          </Fade>
          <Fade bottom distance="70%">
            <dsiv className="contact-location2">
              <h3 className="contact-title">{t("ContactAddress.7")}</h3>
              <p className="contact-text">{t("ContactAddress.8")}</p>
              <div className="contact-social-icons">
                <div>
                  <FaInstagram color="white" size="30px" />
                </div>
                <div>
                  <FaFacebookF color="white" size="30px" />
                </div>
                <div>
                  <FaTwitter color="white" size="30px" />
                </div>
              </div>
            </dsiv>
          </Fade>
        </div>
      </div>
      <div className="contact-map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3987.1021416009444!2d29.734300917443846!3d-2.1142916000000036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dccb9494f623a3%3A0xfd7c42792f93538f!2sMUHANGA%20INDUSTRIAL%20PARK!5e0!3m2!1sen!2set!4v1674026411790!5m2!1sen!2set"></iframe>
      </div>
    </div>
  );
};

export default ContactAddress;
